/* KEYFRAMES */

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes spin3D {
    from {
        transform: rotate3d(0.5, 0.5, 0.5, 360deg);
    }
    to {
        transform: rotate3d(0deg);
    }
}

@keyframes configure-clockwise {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes configure-xclockwise {
    0% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(-135deg);
    }
    75% {
        transform: rotate(-225deg);
    }
    100% {
        transform: rotate(-315deg);
    }
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0.25;
        transform: scale(0.75);
    }
}

/* GRID STYLING */

* {
    box-sizing: border-box;
}

#body {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

/* PULSE BUBBLES */

.pulse-container {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pulse-bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0dcaf0;
}

.pulse-bubble-1 {
    animation: pulse 0.2s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse 0.2s ease 0.1s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse 0.2s ease 0.2s infinite alternate;
}

/* spinner */
#body {
    font-weight: 500 !important;
}
#body :where(.css-dev-only-do-not-override-ed5zg0).ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    right: 26px !important;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    top: 5px !important;
    font-size: 15px;
    font-weight: 500;
}
#body :where(.css-ed5zg0).ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin-top: -26px;
    margin-left: 8px;
}
/* spinner */
