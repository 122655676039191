.error-button {
    height: 90vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-btn {
    background-color: rgba(238, 2, 2, 0.507);
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 26px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    border-radius: 30px;
}
