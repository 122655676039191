@media only screen and (max-width: 991px) {
    .css-1sn4lm3-MuiTypography-root {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .css-1hv8oq8-MuiStepLabel-label {
        display: none !important;
    }
}

@media screen and (max-width: 1000px) {
    #img_span {
        width: 100% !important;
    }
}
@media screen and (max-width: 1000px) {
    .res_modal {
        width: 90% !important;
    }
}
@media screen and (max-width: 500px) {
    .container_diploma {
        flex-direction: column !important;
    }
    .container_item {
        margin-top: 10px;
    }
}
#img_span {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    background-color: rgb(226, 226, 226);
    padding: 7px 13px;
    border-radius: 3px;
}

#img_span > span,
#resume {
    text-decoration: underline;
    color: rgb(97, 97, 97);
    font-weight: 500;
    cursor: pointer;
}
.all__center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.skill_lbl {
    color: #aaa;
}
.card-brd {
    height: 100%;
    box-shadow: rgba(139, 139, 139, 0.45) 0px 4px 4px -2px, rgba(101, 101, 101, 0.48) 0px 0px 1px 1px;
    border-radius: 4px;
    padding-top: 20px !important;
}
.card-brd span {
    font-size: 13pt;
    padding: 0 12px;
    line-height: 30px;
    cursor: pointer;
    height: 33px;
    user-select: none;
}
#revSkills .card-brd {
    border: 1px solid #ddd;
}
.modal-body {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}
.m__item {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 5px 15px !important;
}
#menu-appbar2 {
    margin-left: 62px !important;
}

.table__bg {
    background-color: rgb(233, 239, 246);
}

#option {
    display: block !important;
    padding: 6px 16px !important;
}
.MuiTablePagination-menuItem {
    display: flex !important;
    /* padding: 6px 16px; */
}

.tabs_box [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    font-weight: bold;
}
.tabs_box {
    min-height: 300px;
    background-color: rgb(252, 252, 252);
    border-radius: 8px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8 {
    margin-top: 0px !important;
}
.input_div {
    position: relative;
}
.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}
.input_div input {
    padding-right: 44px;
}

#lbl_input {
    font-size: 13px;
    margin: 0 0 4px 1px;
    color: gray;
}

.hrp-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20000000;
    background-color: transparent;
    cursor: wait !important;
}
.error_lbl {
    color: red;
    padding-left: 5px;
    margin-top: 3px;
    font-size: 14px;
}
.css-1x97c6v {
    width: 100% !important;
}
.marginBottom {
    margin-top: 10px !important;
}
.MuiBox-root css-15edzf2 {
    padding: 10px !important;
}
.csv-obj-key {
    font-weight: 600;
}
#csv-file {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
}

.hourglass {
    display: block;
    background: white;
    margin: 3em auto;
    width: 2em;
    height: 4em;
    animation: hourglass 1s linear infinite;
}
.outer {
    fill: #00b7c6;
}
.middle {
    fill: white;
}
@keyframes hourglass {
    0% {
        transform: rotate(0deg);
        box-shadow: inset #00b7c6 0 0em 0 0, inset white 0 -2em 0 0, inset #00b7c6 0 -4em 0 0;
    }
    80% {
        transform: rotate(0deg);
        box-shadow: inset #00b7c6 0 -2em 0 0, inset white 0 -2em 0 0, inset #00b7c6 0 -2em 0 0;
    }
    100% {
        transform: rotate(180deg);
        box-shadow: inset #00b7c6 0 -2em 0 0, inset white 0 -2em 0 0, inset #00b7c6 0 -2em 0 0;
    }
}
.pointer {
    cursor: pointer;
}

.overflow_scroll {
    overflow-y: auto !important;
    height: 90vh !important;
}

.MainNavigation_dropdownItem__V07ZH {
    margin-left: 10px;
}
.dropdownNav > * {
    padding-left: 15px !important;
}
.dropdownNav > *:hover {
    background-color: #e6e8ff !important;
    color: #212529 !important;
}
.timeoff_card {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.timeoff_card_btn {
    margin-bottom: 20px;
    margin-right: 20px;
}
.notes_link {
    cursor: pointer;
    margin-left: 20px;
    color: white;
}
.pointer {
    cursor: pointer;
}

.err_comment {
    font-size: 12px;
    margin: 3px 14px 0;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    color: #d32f2f;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.notification-icon {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    background-color: #999;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.notification-tooltip {
    position: absolute;
    background-color: #dc3545;
    bottom: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
