body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    padding: 0 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html {
    overflow-y: auto;
    overflow-x: hidden;
}

.table-responsive {
    width: 100% !important;
    height: 100% !important;
}
#form:nth-child(1) .card-header {
    display: none;
}
.css-levciy-MuiTablePagination-displayedRows,
.css-pdct74-MuiTablePagination-selectLabel {
    margin-bottom: 0px;
}
.error {
    color: #d32f2f;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 3px 0 0;
}
#label {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 11.5px;
    line-height: 1.3375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 133%;
    margin-left: 3.5px;
}

.mat_label {
    font-size: 11px;
    color: rgba(129, 129, 129, 0.74);
    margin: 0;
    padding: 0;
    display: block;
}
.cursor {
    cursor: pointer;
}
.pr_icon {
    padding: 20px;
    font-size: 178px !important;
    color: rgb(0, 0, 0, 0.7);
}
.left_right {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.css-1tw2i9j-MuiGrid-root > .MuiGrid-item {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding-left: 16px;
}
#head_content {
    margin: 16px;
    padding: 5px;
    min-height: 92vh;
}

.file_md {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 400px) {
    #head_content {
        margin: 16px 0px;
        padding: 5px;
        min-height: 90vh;
    }

    .Dashboard h4 {
        font-size: 18px;
    }
    .apexcharts-legend-text {
        font-size: 8px !important;
    }

    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
        width: 50vw !important;
        padding: 0 !important;
    }
}
.screen_timeOff {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12000;
    cursor: wait;
}
.right_lefttt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}
