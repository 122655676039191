.policy {
    padding: 20px;
}
.DropContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.Board {
    width: 700px;
    min-width: 250px;
    min-height: 400px;
    border: 5px solid lightgray;
    padding: 20px 10px;
    border-radius: 12px;
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Board_title {
    font-size: 1.33rem;
    font-weight: 600;
}
.drop_item {
    width: 100%;
    border: 2px solid rgb(0, 168, 168);
    padding: 10px;
    border-radius: 6px;
    margin: 5px 0px;
    cursor: pointer;
    background-color: #fcfcf9;
}

.form_items {
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_items select {
    flex: 10% 10% 10%;
}
.form_items label {
    min-width: 100px;
}

@media only screen and (max-width: 550px) {
    .policy {
        padding: 5px;
    }
    .DropContainer {
        flex-direction: column;
        align-items: center;
    }
    .Board {
        width: 100%;
        min-width: 200px;
    }
    .form_items {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}
