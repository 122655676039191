.header {
    width: 100%;
    height: 5rem;
    display: flex;
    padding: 0 10%;
    justify-content: space-between;
    align-items: center;
    background-color: #008080;
}

.logo {
    font-size: 2rem;
    color: white;
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav li {
    margin-left: 1.5rem;
    font-size: 1.25rem;
}

.nav a {
    text-decoration: none;
    color: #88dfdf;
}

.nav a:hover,
.nav a:active,
.nav a.active {
    color: #e6fcfc;
}
.avatarImg {
    width: 154px;
    height: 154px;
}
.gradientCustom {
    /* background: #f6d365;
    background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
    background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1)) */

    background: #0b0b44;
    background: -webkit-linear-gradient(to right bottom, #16166e, rgba(253, 160, 133, 1));
    background: linear-gradient(to right bottom, #0b0b44, rgba(253, 160, 133, 1));
}

.dropdownItem {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: var(--mdb-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    height: 36px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.profilePic {
    margin: 0 auto;
    height: 152px;
    width: 152px;
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}
.profileInput {
    display: none;
    z-index: -2;
}

.profileImg {
    position: absolute;
    object-fit: cover;
    width: 154px;
    height: 154px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
}

.profileLabel {
    position: absolute;
    cursor: pointer;
    height: 154px;
    width: 154px;
}

.profilePic:hover .profileLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    color: rgb(250, 250, 250);
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
}
.imgLoading {
    position: absolute;
    height: 154px;
    width: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.8);
    background-image: url("../assets/avatar.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: rgb(250, 250, 250);
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
    z-index: 2;
}

.profileSpan {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
}

@media screen and (max-width: 556px) {
    .profilePic {
        width: 108px !important;
        height: 108px !important;
    }
    .profileImg {
        width: 110px !important;
        height: 110px !important;
    }
    .profileLabel {
        width: 110px !important;
        height: 110px !important;
    }
}
