.mdl-card {
    width: 550px;
    min-height: 0;
    margin: 10px auto;
}

.mdl-card__supporting-text {
    width: 100%;
    padding: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
    width: 25%;
}

.mdl-stepper-horizontal-alternative {
    display: table;
    width: 100%;
    margin: 0 auto;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
    display: table-cell;
    position: relative;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:hover,
.mdl-stepper-horizontal-alternative .mdl-stepper-step:active {
    background-color: rgba(0, 0, 0, 0.06);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:active {
    border-radius: 15% / 75%;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:hover .mdl-stepper-circle {
    background-color: #757575;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:first-child .mdl-stepper-bar-left,
.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child .mdl-stepper-bar-right {
    display: none;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    background-color: #9e9e9e;
    border-radius: 50%;
    text-align: center;
    line-height: 2em;
    font-size: 12px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
    background-color: rgb(33, 150, 243);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle:before {
    content: "✓";
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle *,
.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle * {
    display: none;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle:before {
    content: "\270E";
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title,
.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-optional {
    text-align: center;
    color: rgba(0, 0, 0, 0.26);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-title {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.step-done .mdl-stepper-title,
.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.editable-step .mdl-stepper-title {
    font-weight: 300;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-optional {
    font-size: 12px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-optional {
    color: rgba(0, 0, 0, 0.54);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left,
.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
    position: absolute;
    top: 12px;
    height: 1px;
    border-top: 1px solid #bdbdbd;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
    color: white;
}

.lg-status {
    display: block !important;
}

.sm-status {
    display: none !important;
}

@media (max-width: 991px) {
    .lg-status {
        display: none !important;
    }
    .sm-status {
        display: flex !important;
    }
}
