.view_item {
    position: relative;
    max-width: 590px;
    width: 100%;
    border-radius: 12px;
}
.view_item .cencel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 15px;
    right: 13px;
    z-index: 2;
    cursor: pointer;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
}
#clear {
    width: 20px;
    height: 20px;
}
.view_item header {
    --start: 15%;
    height: 90px;
    background-image: repeating-radial-gradient(
            circle at var(--start),
            transparent 0%,
            transparent 10%,
            rgba(54, 89, 219, 0.33) 10%,
            rgba(54, 89, 219, 0.33) 17%
        ),
        linear-gradient(to right, #5b7cfa, #3659db);
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}
.view_item header {
    display: flex;
    align-items: center;
    padding: 0 5%;
}
.view_item {
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
    border-radius: 13px !important;
}

/* date */

.view_item .datestamp {
    width: 4rem;
}
.view_item .datestamp:not(.sr-only) {
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
}
.view_item .datestamp-month {
    background-color: #e71321;
    color: white;
    position: relative;
}
.view_item .datestamp-day {
    color: #5b7cfa;
    font-size: 2.1rem;
    line-height: 1;
}
.view_item .datestamp-month .small {
    width: 3rem;
}
.view_item .datestamp-month .datestamp-day {
    font-size: 2rem;
    line-height: 1;
}
.view_item .small:not(.sr-only) {
    font-size: 0.875rem;
}
.view_item .datestamp,
.view_item .date-body {
    position: relative;
    z-index: 2;
}
.view_item .d_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hours {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #e71321;
}
.view_item h4 {
    font-size: 22px;
    color: #5b7cfa;
}
.view_item .date-time {
    font-size: 15px;
}
